@import '../../node_modules/@mhe/he-bs-themes/src/5/he-bs5/index.scss';
// import bootstrap scss. More info: https://getbootstrap.com/docs/5.2/customize/overview/
@import '../../node_modules/bootstrap/scss/bootstrap';

// TODO: Revisit nav styling to be moved to he-bs-themes.
.nav-tabs-wrapper {
  border-bottom: 1px solid $gray;
}

.nav-link {
  position: relative;
  cursor: pointer;
  border: none;
  padding: 0.5rem 0.25rem;
  background: transparent;
  color: $he-blue;
}

.nav-link:focus-visible {
  outline: $he-blue auto 1px;
}

.nav-link:hover,
.nav-link:focus {
  color: $he-blue;
}

.nav-link:hover:not(:focus-visible)::after,
.nav-link:focus:not(:focus-visible)::after,
.nav-link.active:not(:focus:focus-visible)::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: $he-blue;
}

.pagination {
  margin: 0;
  --bs-pagination-disabled-bg: $text-light !important;
}

ngb-popover-window .popover-body {
  padding: 1.2rem !important;
  font-size: 0.9rem;
}