@import '../../node_modules/@mhe/he-bs-themes/src/5/he-bs5/index.scss';

.student-view,
.instructor-view {
  // 100vh - (header height + header margin + footer height + footer margin)
  min-height: calc(100vh - 8rem);
  padding-top: 1.5rem;
}

.translated-paragraph {
  white-space: pre-line;
}

.tooltip-heading {
  font-size: 1.25rem;
  margin-bottom: 0.6rem;
}

.tooltip-instruction {
  margin-left: 1rem;
  margin-bottom: 0.7rem;
}

.tooltip-instruction-points {
  margin-left: 0.9rem;
}

// This class can be applied to an element (for example characters remaining announcement)
// to make that element visually hidden while remaining available to assistive technology.
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.menu-btn {
  border: 1px solid transparent;
  border-radius: 0.5rem;
  height: 2.61rem;
  width: 3rem;
  padding: 0.56rem 0.8rem;
}

.menu-btn:is(:focus, :hover) {
  background-color: transparent !important;
  border: 1px solid;
}

.btn-border {
  border-radius: 0.5rem;
}

table {
  tr {
    th {
      background-color: $he-blue !important;
      color: $text-light !important;
    }
  }
}
